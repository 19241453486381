import * as React from 'react';
import { Link } from 'gatsby';
import Seo from '../../components/seo';
import Layout from '../../components/layout';
import { useEffect, useState } from 'react';
import TestResult from "../../components/test-result";

const RemovedHeadersPage = () => {
  const [tests, setTests] = useState({
    'x-client-data': { value: '', passed: undefined },
    'Device-Memory': { value: '', passed: undefined },
    'Downlink': { value: '', passed: undefined },
    'Dpr': { value: '', passed: undefined },
    'Ect': { value: '', passed: undefined },
    'Rtt': { value: '', passed: undefined },
    'Sec-Ch-Prefers-Color-Scheme': { value: '', passed: undefined },
    'Sec-Ch-Ua': { value: '', passed: undefined },
    'Sec-Ch-Ua-Arch': { value: '', passed: undefined },
    'Sec-Ch-Ua-Full-Version': { value: '', passed: undefined },
    'Sec-Ch-Ua-Mobile': { value: '', passed: undefined },
    'Sec-Ch-Ua-Model': { value: '', passed: undefined },
    'Sec-Ch-Ua-Platform': { value: '', passed: undefined },
    'Sec-Ch-Ua-Platform-Version': { value: '', passed: undefined },
  });

  const runTest = async () => {
    let newState = { ...tests };

    try {
      const response = await fetch('/backend/remove-headers');
      const results = await response.json();

      const headers = [
        'x-client-data', 'Device-Memory', 'Downlink', 'Dpr', 'Ect', 'Rtt',
        'Sec-Ch-Prefers-Color-Scheme', 'Sec-Ch-Ua', 'Sec-Ch-Ua-Arch',
        'Sec-Ch-Ua-Full-Version', 'Sec-Ch-Ua-Mobile', 'Sec-Ch-Ua-Model',
        'Sec-Ch-Ua-Platform', 'Sec-Ch-Ua-Platform-Version'
      ];
      for (let h of headers) {
        newState[h]['passed'] = results[h]['result'];
        newState[h]['value'] = results[h]['value'];
      }

      setTests(newState);
    } catch (e) {
    }
  }

  useEffect(() => {
    runTest();
  }, []);

  return (
    <Layout>
      <h1>Removed headers</h1>

      <p>
        Crumbs removes some headers to protect you from tracking and hide some bits
        of information.
      </p>

      <button
        className="bg-emerald-600 text-white w-full mb-10 mt-5"
        onClick={() => {
          window.location.reload();
        }}
      >
        Run test
      </button>

      <table className="table-fixed border-collapse border-2 text-left w-full mb-10">
        <thead className="bg-slate-200">
        <tr>
          <th className="border border-slate-300 font-semibold p-4">Header</th>
          <th className="border border-slate-300 font-semibold p-4">Value</th>
          <th className="border border-slate-300 font-semibold p-4">Test result</th>
        </tr>
        </thead>
        <tbody>
        {
          Object.keys(tests).map((test) => (
            <tr className="border-2" key={test}>
              <td className="border p-4">
                <code>{test}</code>
              </td>
              <td className="border p-4">
                {
                  tests[test].value ?
                    <code>{tests[test].value}</code> :
                    <small><i>(empty)</i></small>
                }
              </td>
              <TestResult result={tests[test].passed} colorStyle="bg">
                <td className="border p-4 text-white">
                  {tests[test].passed === undefined ? 'In progress' : tests[test].passed ? 'Passed' : 'Failed'}
                </td>
              </TestResult>
            </tr>
          ))
        }
        </tbody>
      </table>

      <Link to="/">
        Go back
      </Link>
    </Layout>
  );
}

export const Head = () => <Seo title="Removed headers"/>
export default RemovedHeadersPage;
